// NPM: MAIN
import React, { useState, useMemo, useEffect } from 'react'
import {
  Modal,
  Segment,
  Form,
  Input,
  Button,
  SemanticWIDTHS,
  Dropdown,
  Dimmer,
  Loader,
} from "semantic-ui-react"
import moment from "moment"
import _ from "../../compat/lodashplus"
import TimeField from "react-simple-timefield";

// CARD
import { DateTextBox } from "../common"
import { ErrorMessage } from "../common"
import ModInfo from "../common/ModInfo"
import SubUserToken from "./SubUserToken"
import SubNarcoticUsage from "./SubNarcoticUsage";

// Utils
import * as Util from "../../utils";

// STYLE
import "./CardMedAdmin.scss"

type ModeType = "PRE_ADMIN" | "ADMIN" | "MED_RECORD"

type ModMedicationRecordDetailStateType = {
  onEvent: (e: any) => any;
  // controller
  controller: any;
  // data
  open?: boolean;
  DJANGO: any;
  record?: any;
  orderItemId?: number | null;
  type: ModeType;
  // config
  isNarcotic?: boolean;
  // callback
  onClose?: () => any;
  onSaved?: () => any;
};

type ElementFormType = {
  date: JSX.Element;
  time: JSX.Element;
  preAdmin: JSX.Element;
  nurse1: JSX.Element;
  nurse2: JSX.Element;
}

type ConfigFormType = {
  [key in ModeType]?: {
    width: SemanticWIDTHS,
    element: keyof ElementFormType
  }[]
}

type InputNurseProps = {
  error: boolean;
  password?: string;
  token: string;
  username?: string;
}

type InputNurseType = {
  nurse1: InputNurseProps;
  nurse2: InputNurseProps;
}


const ModMedicationRecordDetailInitial: ModMedicationRecordDetailStateType = {
  open: false,
  onClose: () => null,
  DJANGO: {},
  record: {},
  orderItemId: null,
  type: "MED_RECORD",
  onEvent: () => null,
  controller: null,
  onSaved: () => null
}

const ACTION = {
  PRE_ADMIN: {
    color: "green",
    text: "SAVE"
  },
  ADMIN: {
    color: "green",
    text: "SAVE"
  },
  START: {
    color: "green",
    text: "START"
  },
  EDIT: {
    color: "yellow",
    text: "EDIT"
  },
  STOP: {
    color: "brown",
    text: "STOP"
  },
}

const initialInput = {
  nurse1: {
    error: false,
    token: ""
  },
  nurse2: {
    error: false,
    token: ""
  }
}

const currentTime = () => Util.formatDatetime(moment()).split(" ")[1]

export const getEmployeeToken = async (data: {
  controller: any;
  inputNurse: any;
  needTwoNurse?: boolean;
}) => {
  const { nurse1, nurse2 } = data.inputNurse;
  const nurse1Data = {
    password: nurse1.password,
    username: nurse1.username,
  };
  const nurse2Data = {
    password: nurse2?.password,
    username: nurse2?.username,
  };

  const [user1, user2] = await Promise.all([
    data.controller.userManager.postUserLogin?.(nurse1Data),
    data.controller.userManager.postUserLogin?.(nurse2Data),
  ]);

  const [employee1, employee2] = await Promise.all([
    user1?.[0] ? data.controller.userManager.getUserEmployee?.({ id: user1[0].user }) : [],
    user2?.[0] ? data.controller.userManager.getUserEmployee?.({ id: user2[0].user }) : [],
  ]);

  const [token1, token2] = await Promise.all([
    employee1?.[0]
      ? data.controller.userManager.getUserTokenize?.({ code: employee1[0].code })
      : [],
    employee2?.[0]
      ? data.controller.userManager.getUserTokenize?.({ code: employee2[0].code })
      : [],
  ]);

  const hasError = !token1?.[0] || (data.needTwoNurse && !token2?.[0]);

  return {
    error: hasError ? "กรุณาระบุ Username และ Password ให้ถูกต้อง" : "",
    tokenNurse1: token1?.[0]?.token || "",
    tokenNurse2: token2?.[0]?.token || "",
  };
};

const ModMedicationRecordDetail: React.FC<ModMedicationRecordDetailStateType> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [date, setDate] = useState<string>()
  const [time, setTime] = useState<string>()

  //* Show mod
  const [openModError, setOpenModError] = useState<any>(null)
  const [errMessage, setErrMessage] = useState<any>(null)

  const [reasonList, setReasonList] = useState<any[]>([])
  const [reason, setReason] = useState<any>("")
  const [division, setDivision] = useState<any[]>([])
  const [adminDivision, setAdminDivision] = useState<any>("")
  const [showInputNurse, setShowInputNurse] = useState<InputNurseType>(initialInput)
  const [remark, setRemark] = useState<string>("")

  // * Narcotic
  const [narcotic, setNarcotic] = useState<{ apply?: string; reject?: string; }>({})

  useEffect(() => {
    if (props.type === "PRE_ADMIN") {
      setRemark(props.record?.preadmin_remark)

      setNarcotic({
        apply: props.record?.apply || "",
        reject: props.record?.reject || "",
      });
    } else {
      setRemark(props.record?.remark)
    }
    setDate(props.record?.admin_date || props.record?.date || Util.formatDate(moment()))
    setTime(props.record?.admin_time || currentTime())
    setReason(props.record?.reason)
    if (props.DJANGO) {
      setAdminDivision(props.record?.admin_division || props.DJANGO?.division?.id)
    }
    if (props.open) {
      onFetchMedRecordDetail()

      onFetchUserDetail()
    }
  }, [props.open])

  useEffect(() => {
    if (props.record) {
      setShowInputNurse({
        ...showInputNurse,
        nurse1: {
          ...showInputNurse.nurse1,
          token: props.record.nurse1
        },
        nurse2: {
          ...showInputNurse.nurse2,
          token: props.record.nurse2
        }
      })
    }
  }, [props.record])

  const onFetchMedRecordDetail = async () => {
    if (props.controller) {
      if (!division[0]) {
        getDivision()
      }
      postClinicalTermSet()
    }
  }

  const onFetchUserDetail = async () => {
    if (props.controller) {
      getUserDetail()
    }
  };

  // ---------------------------- FETCH API, method: GET ---------------------
  const getDivision = async () => {
    const [res] = await props.controller.coreManager.getDivision({ for_opd_encounter: true });

    if (res?.items[0]) {
      setDivision(res.items)
    }
  }

  const getUserDetail = async()=> {
    const res = await props.controller.userManager.getUserDetail?.({ id: props.DJANGO.user.id });

    if (res?.[0]) {
      handleChangeInputNurse("nurse1", { name: "username", value: res[0].username });
    }
  }

  // ---------------------------- FETCH API, method: POST ---------------------
  const postClinicalTermSet = async () => {
    const [res] = await props.controller.coreManager.getClinicalTermSet({ questionList: ["ADMIN_REMARK"] });
    if (res?.ADMIN_REMARK) {
      setReasonList(res.ADMIN_REMARK.items)
    }
  }

  // ---------------------------- END FETCH API ----------------------------

  const onSetStyleError = (key: keyof InputNurseType, err: any) => {
    setErrMessage(<ErrorMessage error={err} />)
    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        error: true
      }
    })
  }

  const handleChangeToken = (key: keyof InputNurseType, token: any) => {
    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        token
      }
    })
  }

  const handleChangeInputNurse = (
    key: keyof InputNurseType,
    data: { name: string; value: any }
  ) => {
    setShowInputNurse({
      ...showInputNurse,
      [key]: {
        ...showInputNurse[key],
        [data.name]: data.value,
      },
    });
  };

  const handleOnSaveRemark = async (action: string) => {
    const {
      expected_admin_date,
      expected_admin_time,
      reference_standard_admin_time,
      rate,
      drug_order_item_id,
      medication_record_id,
      needTwoNurse,
      alertTypes,
    } = props.record;

    setIsLoading(true);

    const nurseTokenResult = await getEmployeeToken({
      controller: props.controller,
      inputNurse: showInputNurse,
      needTwoNurse,
    });
    const { error, tokenNurse1, tokenNurse2 } = nurseTokenResult;

    if (error) {
      const errorMsg = {
        ...(!tokenNurse1 && {
          nurse1: showInputNurse.nurse1.password ? error : "กรุณาระบุ Nurse 1",
        }),
        ...(!tokenNurse2 &&
          needTwoNurse &&
          (showInputNurse.nurse2.password || showInputNurse.nurse2.username
            ? {
                nurse2: error,
              }
            : {
                [`เนื่องจากว่ามียาประเภท ${(alertTypes || []).join(", ")}`]:
                  "กรุณาระบุ Nurse 2",
              })),
      };

      setErrMessage(<ErrorMessage error={errorMsg} />);
      setIsLoading(false);

      return;
    }

    const params: any = {
      action,
      nurse1: tokenNurse1,
      nurse2: tokenNurse2,
      admin_date: date,
      rate: rate || "",
      reason,
      ...(props.isNarcotic ? { apply: narcotic.apply, reject: narcotic.reject } : {}),
    };
    let response = []

    let paramsMedRecord = {
      ...params,
      order_item: drug_order_item_id,
      expected_admin_date,
      expected_admin_time,
      admin_time: time,
      reference_standard_admin_time
    }

    const paramsItems = [{
      drug_item_id: drug_order_item_id,
      expected_admin_date,
      expected_admin_time,
      admin_time: time,
      reference_standard_admin_time
    }]

    if (props.type === "PRE_ADMIN") {
      params.items = paramsItems
      params.preadmin_remark = remark
      paramsMedRecord.preadmin_remark = remark
      if (medication_record_id) {
        response = await props.controller.tpdManager.putMedAdminRecord(
          medication_record_id, paramsMedRecord
        )
      } else {
        response = await props.controller.tpdManager.postCreateMedAdminRecord(params)
      }

    }
    else if (props.type === "ADMIN") {
      const paramsAdmin = {
        ...params,
        items: paramsItems,
        remark
      }

      if (medication_record_id) {
        response = await props.controller.tpdManager.putUpdateMedAdminRecord(
          medication_record_id, paramsAdmin)
        // response = await props.controller.tpdManager.putMedAdminRecord(
        //   medication_record_id, paramsMedRecord)
      } else {
        // response = await props.controller.tpdManager.postMedAdminRecord(paramsMedRecord)
        response = await props.controller.tpdManager.postCreateMedAdminRecord(paramsAdmin)
      }

    } else {
      paramsMedRecord.remark = remark
      if (medication_record_id) {
        response = await props.controller.tpdManager.putMedAdminRecord(
          medication_record_id, paramsMedRecord)
      } else {
        response = await props.controller.tpdManager.postMedAdminRecord(paramsMedRecord)
      }
    }
    const [, err] = response

    setIsLoading(false)
    if (err) {
      if (err.hasOwnProperty("nurse1")) {
        onSetStyleError("nurse1", { nurse1: err.nurse1 })

        return
      }
      if (err.hasOwnProperty("nurse2")) {
        onSetStyleError("nurse2", { nurse2: err.nurse2 })

        return
      }
      setOpenModError(err)
    } else {
      if (props.type !== "PRE_ADMIN") {
        await props.controller.tpdManager.putDrugOrderItemIdAdminDivision(
          drug_order_item_id, {
          admin_division: adminDivision,
          admin_remark: props.record?.admin_remark || ""
        })
      }
      props.onSaved && props.onSaved()
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    setAdminDivision("")
    setRemark("")
    setTime(currentTime())
    setErrMessage(null)
    setShowInputNurse(initialInput)
    setReason("")
  }

  /**
   * Narcotic
   */
  const handleChangeInput = (data: any) => {
    setNarcotic({
      ...narcotic,
      [data.name]: data.value
    })
  }

  /**
   * Set form for create
   */
  const ElementForm: ElementFormType = useMemo(() => {
    return {
      date: (
        <>
          <label>Date</label>
          <DateTextBox value={date} onChange={(value: string) => setDate(value)} />
        </>
      ),
      time: (
        <>
          <label>Time</label>
          <TimeField
            input={<Input icon="clock outline" />}
            value={time}
            onChange={(ev, value) => setTime(value)}
          />
        </>
      ),
      preAdmin: (
        <>
          <label style={{ width: "76px" }}>Pre Time</label>
          <TimeField
            input={<Input icon="clock outline" />}
            value={time}
            onChange={(ev, value) => setTime(value)}
          />
        </>
      ),
      nurse1: (
        <>
          <label style={{ minWidth: "max-content" }}>Nurse 1</label>
          {props.DJANGO && props.record && (
            // <SubUserToken
            //   token={props.record.nurse1 || props.DJANGO.user.token}
            //   controller={props.controller}
            //   error={showInputNurse?.nurse1?.error}
            //   onChangeToken={(token) => {
            //     handleChangeToken("nurse1", token)
            //   }}
            // />
            <>
              <Input disabled value={props.DJANGO.user.full_name} />
              <label style={{ minWidth: "max-content", margin: "0 1rem" }}>Password</label>
              <Input
                type="password"
                value={showInputNurse?.nurse1?.password}
                onChange={(e, data) =>
                  handleChangeInputNurse("nurse1", { name: "password", value: data.value })
                }
              />
            </>
          )}
        </>
      ),
      nurse2: props.record?.needTwoNurse && (
        <>
          {
            <>
              <label style={{ minWidth: "max-content" }}>Nurse 2 : Username</label>
              {props.DJANGO && props.record && (
                // <SubUserToken
                //   token={props.record.nurse2}
                //   controller={props.controller}
                //   error={showInputNurse?.nurse2.error}
                //   onChangeToken={(token) => {
                //     handleChangeToken("nurse2", token);
                //   }}
                // />
                <>
                  <Input
                    value={showInputNurse?.nurse2?.username || ""}
                    onChange={(e, data) =>
                      handleChangeInputNurse("nurse2", { name: "username", value: data.value })
                    }
                  />
                  <label style={{ minWidth: "max-content", margin: "0 1rem" }}>Password</label>
                  <Input
                    type="password"
                    value={showInputNurse?.nurse2?.password || ""}
                    onChange={(e, data) =>
                      handleChangeInputNurse("nurse2", { name: "password", value: data.value })
                    }
                  />
                </>
              )}
            </>
          }
        </>
      ),
      remark: (
        <>
          <label>Remark</label>
          <Input fluid value={remark} onChange={(ev, input) => setRemark(input.value)} />
        </>
      ),
    };
  }, [showInputNurse, date, remark]);
  /**
   * Array form list
   */
  const configForm: ConfigFormType = useMemo(
    () => ({
      PRE_ADMIN: [
        [
          {
            width: 5,
            element: "preAdmin",
          },
          {
            width: 11,
            element: "nurse1",
          },
        ],
        [
          {
            width: 16,
            element: "nurse2",
          },
        ],
      ],
      ADMIN: [
        [
          {
            width: 3,
            element: "date",
          },
          {
            width: 4,
            element: "time",
          },
          {
            width: 9,
            element: "nurse1",
          },
        ],
        [
          {
            width: 16,
            element: "nurse2",
          },
          {
            width: 16,
            element: "remark",
          },
        ],
      ],
    }),
    []
  );

  const buttonAction = useMemo(() => {
    const cloneAction: any = { ...ACTION }

    if (props.type === "PRE_ADMIN")
      delete cloneAction.ADMIN
    if (props.type === "ADMIN")
      delete cloneAction.PRE_ADMIN
    if (props.type === "MED_RECORD")
      delete cloneAction.PRE_ADMIN

    return cloneAction
  }, [props.record])

  const adminDivisionOptions = useMemo(() => {
    return division ?
      [{ key: "", text: "All", value: " " },
      ..._.map(division, (item: any, index: number) => ({ key: index, text: item.name, value: item.id }))
      ] : []
  }, [division])

  const reasonOptions = useMemo(() => {
    return reasonList ?
      _.map(reasonList, (item: any, index: number) => ({ key: index, text: item.name, value: item.id }))
      : []
  }, [reasonList]);

  return (
    <Modal
      open={props.open}
      style={{ margin: "auto" }}
      onClose={() => {
        props.onClose && props.onClose();
        handleCloseModal();
      }}
      size={props.type === "PRE_ADMIN" ? "small" : "large"}
    >
      <Segment style={{ paddingBottom: "0.5rem" }}>
        {errMessage}
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        <Form>
          {configForm[props.type === "PRE_ADMIN" ? "PRE_ADMIN" : "ADMIN"]?.map(
            (children, pIndex: number) => (
              <Form.Group inline>
                {children.map((item, cIndex: number) => (
                  ElementForm[item.element] && <Form.Field
                    key={`field-${pIndex}-${cIndex}`}
                    width={item.width}
                    className="--readOnly --grey"
                  >
                    {ElementForm[item.element]}
                  </Form.Field>
                ))}
              </Form.Group>
            )
          )}
          {props.isNarcotic && (
            <SubNarcoticUsage
              defaultValue={narcotic}
              drugName={props.record?.drug_name}
              unitName={props.record?.drug_unit?.toLowerCase()}
              onChange={handleChangeInput}
            />
          )}
          {props.type === "PRE_ADMIN" && (
            <Form.Group inline>
              <Form.Field width={16}>
                <label>Remark</label>
                <Input fluid value={remark} onChange={(ev, input) => setRemark(input.value)} />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Group inline>
            {props.type !== "PRE_ADMIN" ? (
              <>
                <Form.Field width={props.type === "ADMIN" ? 6 : 7}>
                  <label style={{ width: "131px" }}>Admin Division</label>
                  <Dropdown
                    size="tiny"
                    className="fluidDropdown"
                    selection
                    search
                    value={adminDivision}
                    options={adminDivisionOptions}
                    onChange={(ev, input) => {
                      setAdminDivision(input.value);
                    }}
                  />
                </Form.Field>
                <Form.Field width={props.type === "ADMIN" ? 8 : 9}>
                  <label>เหตุผล</label>
                  <Dropdown
                    size="tiny"
                    className="fluidDropdown"
                    selection
                    search
                    clearable
                    value={reason}
                    options={reasonOptions}
                    onChange={(ev, input) => {
                      setReason(input.value);
                    }}
                  />
                </Form.Field>
              </>
            ) : (
              <Form.Field width={12}></Form.Field>
            )}
            <Form.Group style={{ margin: 0 }}>
              {props.type !== "MED_RECORD" ? (
                <Form.Field>
                  <Button
                    color={"green"}
                    floated="right"
                    onClick={() => {
                      const compare = props.record?.allowed_actions?.includes("EDIT");
                      compare
                        ? handleOnSaveRemark(props.type === "PRE_ADMIN" ? "EDIT_PRE_ADMIN" : "EDIT")
                        : handleOnSaveRemark(props.type);
                    }}
                    disabled={
                      props.isNarcotic
                        ? (narcotic.apply) < 0 && Number(narcotic.reject) < 0
                        : false
                    }
                  >
                    SAVE
                  </Button>
                </Form.Field>
              ) : (
                Object.keys(buttonAction).map((key, index) => {
                  return props.record?.allowed_actions?.includes(key) ? (
                    <Form.Field key={"button-" + index}>
                      <Button
                        color={(ACTION as any)[key].color}
                        floated="right"
                        onClick={() => handleOnSaveRemark(key)}
                      >
                        {(ACTION as any)[key].text}
                      </Button>
                    </Form.Field>
                  ) : null;
                })
              )}
            </Form.Group>
          </Form.Group>
        </Form>
      </Segment>
      <ModInfo
        type={"error"}
        titleName={""}
        closeOnDimmerClick
        open={!!openModError}
        onApprove={() => setOpenModError(null)}
        onClose={() => setOpenModError(null)}
      >
        <ErrorMessage error={openModError} />
      </ModInfo>
    </Modal>
  );
}
ModMedicationRecordDetail.defaultProps = ModMedicationRecordDetailInitial
export default ModMedicationRecordDetail
